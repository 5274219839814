import { Table } from "antd";
import React from "react";

const PackageTableExpanded = ({ data }) => {
  const membershipPackageColumns = [
    {
      title: "Action",
      key: "action",
      width: 100,
      className: "header-white",
      align: "center",
    },
    {
      title: "Status",
      className: "header-white",
      key: "status",
      align: "center",
    },
    {
      title: "Package",
      key: "package",
      className: "header-white",
      align: "center",
    },
    {
      title: "Season",
      dataIndex: "season",
      key: "season",
      className: "header-white",
      align: "center",
      render: (season) => season,
    },
    {
      title: "Total Sold",
      dataIndex: "totalSold",
      className: "header-white",
      align: "center",
      render: (data) => "-",
    },
    {
      title: "Total on Auto-Renewal",
      dataIndex: "totalAutoRenewal",
      className: "header-white",
      align: "center",
      render: (data) => "-",
    },
    {
      title: "Auto-Renew Cancelled",
      key: "autoRenewCancelled",
      className: "header-white",
      align: "center",
    },
    {
      title: "Children",
      dataIndex: "products",
      className: "header-white",
      align: "center",
      render: (data) => data?.length || 0,
    },
    {
      title: "Total $",
      dataIndex: "totalMoney",
      className: "header-white",
      align: "center",
      render: (data) => "-",
    },
    {
      title: "Packages",
      dataIndex: "packages",
      className: "header-white",
      align: "center",
      render: (data) => "-",
    },
    {
      title: "Store",
      dataIndex: "store",
      className: "header-white",
      align: "center",
      render: (data) => "-",
    },
    {
      title: "Donations",
      dataIndex: "donations",
      className: "header-white",
      align: "center",
      render: (data) => "-",
    },
    {
      title: "Price",
      dataIndex: "price",
      className: "header-white",
      align: "center",
      render: (price) => <span>{price.amount}</span>,
    },
    {
      title: "Child Price",
      dataIndex: "childPrice",
      className: "header-white",
      align: "center",
      render: (childPrice) => <span>{childPrice && childPrice.amount}</span>,
    },
    {
      title: "Package Expires",
      key: "packageExpiryDate",
      className: "header-white",
      align: "center",
    },
    {
      title: "Auto-renew date",
      key: "autoRenewDate",
      className: "header-white",
      align: "center",
    },
  ];

  const nestedTable = [];

  return (
    <Table
      showHeader={false}
      columns={membershipPackageColumns}
      dataSource={nestedTable}
      pagination={false}
      bodyStyle={{
        fontSize: window.screen.width > 500 ? "18px" : "12px",
        backgroundColor: "#ffffff",
      }}
      className="table-backdrop"
    />
  );
};

export default PackageTableExpanded;
