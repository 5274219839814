import { Table } from "antd";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import {
  getPackages,
  setPackageSortOrder,
} from "../../../services/firebaseService/endPoints/admin/members";
import MemberPackageForm from "./MemberPackageForm";
import GripVertical from "../../../images/grip-vertical.svg";
import moment from "moment";
import PackageTableExpanded from "./PackageTableExpanded";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../services/firebaseService/connection";
import { getCurrencyUnicode } from "../../../helperFunctions/util";
import DraggableBodyRow from "../ourChants/DraggableBodyRow";

const shippingOptions = [
  { value: "pickup", label: "Pick up only" },
  { value: "shipping", label: "Shipping only" },
  { value: "both", label: "Option of shipping or pickup" },
];

class MembershipPackageTable extends React.Component {
  state = {
    tableData: [],
    needsUpdate: false,
    addOrEditPackageModal: false,
    id: "",
    transactionsByPackage: {},
  };

  fetchTransactionsFromPackages = async (packages) => {
    try {
      this.setState({
        isLoading: true,
      });

      const transactionsByPackage = {};

      const promises = packages.map(async (pkg) => {
        let dbQuery = query(
          collection(
            db,
            "transaction_record",
            this.props.currentGroup,
            "records"
          ),
          where("packageId", "==", pkg?.id)
        );

        const snap = await getDocs(dbQuery);
        let transactions = [];
        for (let doc of snap.docs) {
          let data = doc.data();
          data.transactionId = doc.id;
          transactions.push(data);
        }

        const extractedData = transactions.reduce(
          (acc, curr) => {
            acc.totalAmount += curr.amount;

            if (
              curr?.subscribedProduct &&
              curr?.subscribedProduct?.length > 0
            ) {
              acc.totalRenewals += 1;
            }

            return acc;
          },
          { totalAmount: 0, totalRenewals: 0 }
        );

        const totalSold = transactions?.length;

        console.log("3214 extractedData: ", extractedData);

        const finalObj = {
          totalAmount: extractedData.totalAmount.toFixed(2),
          totalRenewals: extractedData.totalRenewals,
          totalSold,
        };

        transactionsByPackage[pkg.id] = finalObj;
        return transactions;
      });

      await Promise.all(promises);

      console.log("3214 transactionsByPackage: ", transactionsByPackage);

      this.setState({
        transactionsByPackage: transactionsByPackage,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  fetchData = () => {
    getPackages(this.props.currentGroup).then((packages) => {
      this.setState({
        tableData: packages,
      });

      this.fetchTransactionsFromPackages(packages);
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.fetchData();
    }
  }

  moveRow = (dragIndex, hoverIndex) => {
    const newData = JSON.parse(JSON.stringify(this.state.tableData));
    newData[dragIndex].sortIndex = hoverIndex + 1;
    let sortIndexChangeStep = hoverIndex - dragIndex > 0 ? -1 : 1;
    let i = sortIndexChangeStep > 0 ? hoverIndex : dragIndex + 1;
    let finalStep = sortIndexChangeStep < 0 ? hoverIndex : dragIndex - 1;
    for (; i <= finalStep; i++) {
      newData[i].sortIndex += sortIndexChangeStep;
    }

    this.setState(() => {
      return { tableData: newData, needsUpdate: true };
    });
  };

  render() {
    console.log("3214 txn: ", this.state.transactionsByPackage);

    const components = {
      body: {
        row: DraggableBodyRow,
      },
    };

    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";

    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    const membershipPackageColumns = [
      {
        title: "Action",
        width: 100,
        className: "header-white",
        render: (data) => {
          return (
            <div className="row" style={{ marginLeft: "15px" }}>
              <img src={GripVertical} alt="*" />
              {data && data.id && (
                <i
                  className="fa fa-pencil cursor-pointer ml-3"
                  onClick={() => {
                    this.setState({ id: data.id, addOrEditPackageModal: true });
                  }}
                ></i>
              )}
            </div>
          );
        },
        align: "center",
      },
      {
        title: "Status",
        dataIndex: "status",
        className: "header-white",
        align: "center",
        render: (status) => status,
      },
      {
        title: "Package",
        dataIndex: "name",
        className: "header-white",
        align: "center",

        render: (name) => name,
      },
      {
        title: "Season",
        dataIndex: "season",
        className: "header-white",
        align: "center",
        render: (season) => season,
      },
      {
        title: "Total Sold",
        className: "header-white",
        align: "center",
        render: (data) =>
          this.state.transactionsByPackage?.[data?.id]?.totalSold ?? "-",
      },
      {
        title: "Auto-Renewal",
        className: "header-white",
        align: "center",
        render: (data) =>
          this.state.transactionsByPackage?.[data?.id]?.totalRenewals ?? "-",
      },
      // {
      //   title: "Auto-Renew Cancelled",
      //   dataIndex: "autoRenewCancelled",
      //   className: "header-white",
      //   align: "center",
      //   render: (data) => "-",
      // },
      {
        title: "Children",
        dataIndex: "products",
        className: "header-white",
        align: "center",
        render: (data) => data?.length || 0,
      },
      {
        title: "Total $",
        className: "header-white",
        align: "center",
        render: (data) => {
          return this.state.transactionsByPackage?.[data?.id]?.totalAmount
            ? `${groupCurrency}${this.state.transactionsByPackage?.[data?.id]?.totalAmount}`
            : "-";
        },
      },
      // {
      //   title: "Packages",
      //   dataIndex: "packages",
      //   className: "header-white",
      //   align: "center",
      //   render: (data) => "-",
      // },
      // {
      //   title: "Store",
      //   dataIndex: "store",
      //   className: "header-white",
      //   align: "center",
      //   render: (data) => "-",
      // },
      // {
      //   title: "Donations",
      //   dataIndex: "donations",
      //   className: "header-white",
      //   align: "center",
      //   render: (data) => "-",
      // },
      {
        title: "Price",
        dataIndex: "price",
        className: "header-white",
        align: "center",
        render: (price) => {
          return price?.amount ? `${groupCurrency}${price?.amount}` : "-";
        },
      },
      {
        title: "Child Price",
        dataIndex: "childPrice",
        className: "header-white",
        align: "center",
        render: (childPrice) => <span>{childPrice && childPrice.amount}</span>,
      },
      {
        title: "Package Expires",
        dataIndex: "packageExpiryDate",
        className: "header-white",
        align: "center",
        render: (date) => {
          if (!date) {
            return "-";
          }

          let dateMoment = moment.unix(date);
          let dateString = `${dateMoment.month() + 1}/${dateMoment.date()}/${dateMoment.year()}`;
          return dateString;
        },
      },
      // {
      //   title: "Auto-renew date",
      //   dataIndex: "autoRenewDate",
      //   className: "header-white",
      //   align: "center",
      //   render: (date) => "-",
      // },
      // {
      //   title: "Price per child",
      //   dataIndex: "childPrice",
      //   className: "header-white",
      //   render: (childPrice) => <span>{childPrice && childPrice.amount}</span>,
      // },
      {
        title: "Item choice?",
        dataIndex: "productChoice",
        className: "header-white",
        render: (choice) => choice,
      },
      {
        title: "Offer Shipping?",
        dataIndex: "offerShipping",
        className: "header-white",
        render: (choice) => {
          let selectedOption = shippingOptions.find(
            (option) => option.value === choice
          );
          let label = "";
          if (selectedOption) {
            label = selectedOption.label;
          }
          return <span>{label}</span>;
        },
      },
      // {
      //   title: "Item choice?",
      //   dataIndex: "productChoice",
      //   className: "header-white",
      //   align: "center",
      //   render: (choice) => choice,
      // },
      // {
      //   title: "Offer Shipping?",
      //   dataIndex: "offerShipping",
      //   className: "header-white",
      //   align: "center",
      //   render: (choice) => {
      //     let selectedOption = shippingOptions.find(
      //       (option) => option.value === choice
      //     );
      //     let label = "";
      //     if (selectedOption) {
      //       label = selectedOption.label;
      //     }
      //     return <span>{label}</span>;
      //   },
      // },
    ];

    let sortedData = this.state.tableData.sort(
      (row1, row2) => row1.sortIndex - row2.sortIndex
    );
    let inactivePackages = sortedData.filter(
      (pack) => pack.status === "inactive"
    );
    let activePackages = sortedData.filter(
      (pack) => pack.status !== "inactive"
    );
    let dataSource = [...activePackages, ...inactivePackages];

    let backgroundColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      backgroundColor = `rgb(${r}, ${g}, ${b})`;
    }
    console.log("3214 pkg: ", dataSource);

    return (
      <>
        {this.state.addOrEditPackageModal && (
          <MemberPackageForm
            visible={this.state.addOrEditPackageModal}
            close={() => {
              this.fetchData();
              this.setState({
                addOrEditPackageModal: false,
                id: "",
              });
              this.props.onCloseModal();
            }}
            id={this.state.id}
          />
        )}
        <div
          className="row px-4"
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <span style={{ fontSize: 18, fontWeight: "bold" }}>
            Membership Package
          </span>
          <div>
            {this.state.needsUpdate && (
              <button
                className="mr-2"
                style={{
                  backgroundColor: backgroundColor,
                  borderRadius: 5,
                  border: "0px none",
                  boxShadow: "transparent 0px 0px 0px",
                  outline: "none",
                  padding: "5px 10px",
                  width: "200px",
                  color: "#ffffff",
                  alignSelf: "flex-end",
                  height: 40,
                }}
                onClick={() => {
                  let idOrderMap = this.state.tableData.reduce(
                    (acc, val, index) => {
                      acc[val.id] = val.sortIndex || index;
                      return acc;
                    },
                    {}
                  );

                  setPackageSortOrder(idOrderMap, this.props.currentGroup).then(
                    () => {
                      this.setState({
                        needsUpdate: false,
                      });
                    }
                  );
                }}
              >
                Update Changes
              </button>
            )}

            <button
              style={{
                backgroundColor: backgroundColor,
                borderRadius: 5,
                border: "0px none",
                boxShadow: "transparent 0px 0px 0px",
                outline: "none",
                padding: "5px 10px",
                width: "200px",
                color: "#ffffff",
                alignSelf: "flex-end",
                height: 40,
              }}
              onClick={() => {
                this.setState({
                  addOrEditPackageModal: true,
                });
              }}
            >
              Add Package
            </button>
          </div>
        </div>
        <div className="mt-4">
          <DndProvider backend={HTML5Backend}>
            <Table
              columns={membershipPackageColumns}
              className="table-backdrop dnd-table"
              dataSource={dataSource}
              onRow={(_, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              components={components}
              bodyStyle={{ backgroundColor: "#ffffff" }}
              loading={this.state.isLoading}
              // expandedRowRender={(record) => (
              //   <PackageTableExpanded data={record} />
              // )}
            />
          </DndProvider>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  let { groupName } = state.adminData.allGroups[currentGroup].data;
  return {
    user: state.user,
    currentGroup,
    adminData: state.adminData.allGroups[currentGroup],
    groupName,
  };
};

export default connect(mapStateToProps)(MembershipPackageTable);
